@import "./AssessmentDefaults.css";

.button{
    border-radius: 28px;
    padding: 8px 16px;
    font-weight: 600;
    font-size: 16px;
    margin-right: 10px;
}

.actionButtonContainer{
    display: flex;
    gap: 5px;
}

.actionButtonPurple{
    background: #DEBEED80;
    color: var(--branded-purple);
    border: none;
    border-radius: 30px;
}

.actionButton{
    background: transparent;
    color: var(--branded-purple);
    border: none;
    border-radius: 30px;
}

.actionButton :global(.p-button){
    background-color:#DEBEED80 ;
}

.sectionMarginBottom{
    margin-bottom: 15px;
}

.dataTable{
    border: var(--lightPurple) 15px solid;
    border-radius: 10px;   
}

.dataTable :global(.p-datatable-thead){
    border-bottom: var(--lightPurple) 10px solid;
}

.tabView{
    height: 100%;
}

.tabView :global(.p-tabview .p-tabview-nav){
    border-top-color: white;
    border-left-color: white;
    border-right-color: white;
    border-bottom-color: #E7E0F1;
    border-width: 0px 0px 3px 0px;
    height: 43px;
    
}

.tabView :global(.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link){
    border-bottom-color: var(--branded-purple);
    color: var(--branded-purple);
    border-width: 0px 0px 3px 0px;
}

.matrixContainer{
    margin-bottom: 10px;
}

.matrixContainer :global(.p-panel-header){
    background: #C39DD5;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.groupContainer{
    margin-bottom: 10px;
}

.groupContainer :global(.p-panel-header){
     background: #EEDEF6; 
}




.questionLayout{
    display: grid;
    grid-template-areas:
      'name'
      'body';
    width:100%;
    column-gap: 10px;   
    border: 3px solid #E7E0F1;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 5px;
    background: #FFFFFF;
    
}

.questionLayoutWithScore{
    display: grid;
    grid-template-areas:
      'name score'
      'body body';
      grid-template-columns: auto 90px;
    width:100%;
    column-gap: 10px;   
    border: 3px solid #E7E0F1;
    border-radius: 16px;
    padding: 10px;
    margin-bottom: 5px;
    background: #FFFFFF; 
}

.groupContainerHorizontal{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.groupContainerHorizontal .questionLayoutWithScore{
    width: 49%;
}

.groupContainerHorizontal .questionLayout{
    width: 49%;
}

.questionLayoutName{
    grid-area: name;
    position: relative;
    margin-bottom: 10px;
}

.questionLayoutScore{
    grid-area: score;
    position: relative;
    margin-bottom: 10px;
    text-align: end;

}

.scoreLabel{
    font-weight: bold;
}

.scoreValue{
    color: #873CAA;
    font-weight: bold;
}

.questionLayoutBody{
    grid-area: body;
    position: relative;
    padding-left: 30px;
}

.horizontalQuestion :global(.radioGroup){
    display: flex;
    flex-direction: row;
    gap: 10px;
    flex-wrap: wrap;
}

.required{
    color: red;
}

.switchControl :global(.p-inputswitch){
    border-radius: 50px;
}

.switchControl :global(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider){
    border-radius: 50px;
}

.switchControl :global(.p-inputswitch.p-inputswitch .p-inputswitch-slider){
    border-radius: 50px;
}


.switchControl :global(.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before){
    border-radius: 25px;
}

.switchControl :global(.p-inputswitch.p-inputswitch .p-inputswitch-slider:before){
    border-radius: 25px;
}


.badge{
    border-radius: 4px;
    padding: 4px 10px 4px 10px;
    font-weight: 600;
    text-align: center;
    width: 100px;
}


.badgeCompleted{
    background: #6fcf9757;
    color: #219653;
}

.badgePendingRequired{
    background: #ff000038;
    color: red ;
}

.badgePending{
    background-color: #D9D9D9;
    color: #888888;
}


.InfoOverlayIconContainer{
    color: #883CAE;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.InfoOverlayPanel{
    background-color: #883CAE;
    border: solid black 1px;
}

.InfoOverlayText{
    color: white;
}